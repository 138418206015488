<template>
  <div>
    <table class="dashboard-sch">
    <thead>
    <tr>
      <th colspan="7" class="tit">
        <v-row no-gutters>
          <v-col cols="3" align-self="center" class="px-2 d-flex justify-start">
            <v-btn small text @click="moveToPrevMonth">&lt; {{ prevMon ? prevMon.month() + 1 : '' }}월</v-btn>
          </v-col>
          <v-col cols="6" align-self="center">
            <div>{{ currMon ? currMon.format('YYYY년 MM월') : '' }}</div>
          </v-col>
          <v-col cols="3" align-self="center" class="px-2 d-flex justify-end">
            <v-btn small text @click="moveToNextMonth">{{ nextMon ? nextMon.month() + 1 : '' }}월 &gt;</v-btn>
          </v-col>
        </v-row>
      </th>
    </tr>
    <tr>
      <th class="tit">일</th>
      <th class="tit">월</th>
      <th class="tit">화</th>
      <th class="tit">수</th>
      <th class="tit">목</th>
      <th class="tit">금</th>
      <th class="tit">토</th>
    </tr>
    </thead>
    <tbody>
    <tr
      v-for="(week, index) in items"
      :key="index"
      @click="selectedWeek = index"
    >
      <td
        v-for="(date, idx) in week"
        :key="idx"
        class="td_blueA"
        :class="date.isToday ? 'today' : ''"
      >
        <div
          class="date"
          :class="!date.isCurr ? 'text--disabled' : ''"
        >
          {{ date.date }}
          <div v-if="selectedWeek === index" class="sch-details text-truncate">
            <div
              v-for="(event, eidx) in date.events"
              :key="eidx"
              class="sch-detail"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    label
                    small
                    :color="event.color"
                    class="px-1 lighten-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEventViewDialog(event)"
                  >
                    <span class="text-truncate">{{ event.title }}</span>
                  </v-chip>
                </template>
                <span>{{ event.title }}</span>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="sch-icons">
            <template v-for="(event, eidx) in date.events">
              <v-tooltip top :key="eidx">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="lighten-2"
                    :color="event.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                </template>
                <span>{{ event.title }}</span>
              </v-tooltip>
            </template>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

    <v-dialog v-model="eventDialog" max-width="500px">
      <event-view-dialog v-if="eventDialog" :item="selectedEvent" @close="closeEventViewDialog"/>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import EventViewDialog from "@/views/main/dialog/EventViewDialog";

export default {
  name: 'EventTable',
  components: {EventViewDialog},
  data() {
    return {
      selectedWeek: -1,
      today: null,
      prevMon: null,
      currMon: null,
      nextMon: null,
      items: [],
      events: [],
      selectedEvent: null,
      eventDialog: false
    }
  },
  mounted() {
    this.today = moment()
    this.currMon = moment()
    this.prevMon = moment().subtract(1, 'month')
    this.nextMon = moment().add(1, 'month')

    this.getEvents()
        .then(this.generateDates)
  },
  methods: {
    refresh() {
      this.items = []
      this.events = []
      this.getEvents()
          .then(this.generateDates)
    },
    moveToPrevMonth() {
      this.nextMon = this.currMon.clone()
      this.currMon = this.prevMon.clone()
      this.prevMon = this.prevMon.subtract(1, 'month')
      this.refresh()
    },
    moveToNextMonth() {
      this.prevMon = this.currMon.clone()
      this.currMon = this.nextMon.clone()
      this.nextMon = this.nextMon.add(1, 'month')
      this.refresh()
    },
    generateDates() {
      const targetMonth = this.currMon
      const startDay = targetMonth.startOf('month').day()
      const endDay = targetMonth.endOf('month').day()

      let dates = []
      const todayYear = this.today.year()
      const todayMonth = this.today.month()
      const todayDate = this.today.date()
      for (let i = 0; i < targetMonth.endOf('month').date(); i++) {
        const dateItem = {
          year: targetMonth.year(),
          month: targetMonth.month(),
          date: i + 1,
          isCurr: true,
          isToday: todayYear === targetMonth.year() && todayMonth === targetMonth.month() && todayDate === (i + 1)
        }
        const dateStr = targetMonth.format('YYYY-MM') + '-' + (dateItem.date < 10 ? '0' + dateItem.date : dateItem.date)

        dateItem.events = this.events.filter((ev) =>
            ev.startDate <= dateStr
            && ev.endDate >= dateStr
        )
        dates.push(dateItem)
      }

      if (startDay > 0) {
        const prevLastDate = this.prevMon.endOf('month').date()
        const prevDates = []
        for (let i = 0; i < startDay; i++) {
          prevDates.push({
            year: this.prevMon.year(),
            month: this.prevMon.month(),
            date: prevLastDate - startDay + (i + 1),
            isCurr: false
          })

        }
        dates = prevDates.concat(dates)
      }
      if (endDay < 6) {
        const nextDates = []
        for (let i = 0; i < 6 - endDay; i++) {
          nextDates.push({
            year: this.nextMon.year(),
            month: this.nextMon.month(),
            date: i + 1,
            isCurr: false
          })
        }

        dates = dates.concat(nextDates)
      }

      let todayIndex = -1
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].isToday) {
          todayIndex = Math.floor(i / 7)
          break;
        }
      }
      this.selectedWeek = todayIndex

      const length = dates.length;
      const divide = Math.floor(length / 7) + (Math.floor( length % 7 ) > 0 ? 1 : 0);
      const weeks = [];
      for (let i = 0; i < divide; i++) {
        weeks.push(dates.splice(0, 7));
      }
      this.items = weeks
    },
    getEvents() {
      return this.$http.get('/api/events/dashboard', {params: {
        searchDate: this.currMon.format('YYYY-MM')
      }})
      .then(({ data }) => {
        this.events = data
      })
    },
    openEventViewDialog(event) {
      this.selectedEvent = event
      this.eventDialog = true
    },
    closeEventViewDialog() {
      this.selectedEvent = null
      this.eventDialog = false
    }
  }
}
</script>

<style scoped>
table.dashboard-sch {
  width: 100%;
}
table.dashboard-sch td {
  vertical-align: top;
  padding: 4px;
  width: 14.28%;
}
table.dashboard-sch td div.date {
  text-align: left;
}
table.dashboard-sch th:first-child, table.dashboard-sch td:first-child {
  color: orangered;
}
table.dashboard-sch th:last-child, table.dashboard-sch td:last-child {
  color: #0369aa;
}
table.dashboard-sch td div.sch-icons {
  text-align: right;
}
table.dashboard-sch td div.sch-details {
  display: table;
  table-layout: fixed;
  width: 100%;
}
table.dashboard-sch td div.sch-detail {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 2px 0;
  padding: 2px;
}
table.dashboard-sch td div.sch-detail > * {
  width: 100%;
}
table.dashboard-sch td.today {
  background-color: rgba(3, 105, 170, .2);
}
</style>
