\<template>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>일정 상세</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-text-field
            v-model="editItem.title"
            label="일정"
            hide-details
            class="txt-events"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editItem.remark"
            label="내용"
            hide-details
            readonly
          ></v-textarea>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="editItem.startDate"
            label="시작일"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="editItem.startTime"
            label="시작시간"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="editItem.endDate"
            label="종료일"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="editItem.endTime"
            label="종료시간"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="default" @click="close('dismiss')">닫기</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EventViewDialog',
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      date1: null,
      date2: null,
      time1: null,
      time2: null,
      editItem: {
        seq: null,
        title: null,
        subTitle: null,
        remark: null,
        startDate: null,
        endDate: null,
        startDt: null,
        startTm: null,
        endDt: null,
        endTm: null,
        color: null,
      },
      color: 0,
      colors: ['grey', 'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', ],
    }
  },
  mounted() {
    this.editItem = Object.assign({}, this.item)
  },
  methods: {
    close(result) {
      this.$emit('close', result)
    },
    cancel() {
      this.$emit('close', 'dismiss')
    },
  }
}
</script>

<style>
.txt-events input {
  text-align: left !important;
}
</style>
